import React from 'react';
import { Accordion } from 'react-bootstrap';

type FAQsProps = {
  questions: {
    question: string | React.ReactNode;
    answer: string | React.ReactNode;
  }[];
};
export const FAQs = ({ questions }: FAQsProps) => {
  return (
    <div>
      <br />
      <Accordion flush>
        {questions.map((v, idx) => {
          return (
            <Accordion.Item key={idx} eventKey={idx.toString()}>
              <Accordion.Header>{v.question}</Accordion.Header>
              <Accordion.Body>
                <p className="py-2 px-1" style={{ fontSize: '1.2rem' }}>
                  {v.answer}
                </p>
              </Accordion.Body>
            </Accordion.Item>
          );
        })}
      </Accordion>
    </div>
  );
};
