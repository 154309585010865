import { useStaticQuery, graphql } from 'gatsby';

export const getLessonsImages = () => {
  const data = useStaticQuery(graphql`
    {
      bowl: file(relativePath: { eq: "lessons/bowl.jpg" }) {
        childImageSharp {
          gatsbyImageData(quality: 90, placeholder: BLURRED)
        }
      }
      kid: file(relativePath: { eq: "lessons/kid.jpg" }) {
        childImageSharp {
          gatsbyImageData(quality: 90, placeholder: BLURRED)
        }
      }
      privateLesson: file(relativePath: { eq: "lessons/private_lesson.jpg" }) {
        childImageSharp {
          gatsbyImageData(quality: 90, placeholder: BLURRED)
        }
      }
    }
  `);

  return {
    bowl: data.bowl.childImageSharp.gatsbyImageData,
    privateLesson: data.privateLesson.childImageSharp.gatsbyImageData,
    kid: data.kid.childImageSharp.gatsbyImageData,
  };
};
