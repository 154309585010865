import React from 'react';
import { SEO } from 'components/seo';
import { Button, Container, Row, Col, Card } from 'react-bootstrap';
import { NavHeader } from 'components/nav_header';
import { GatsbyImage } from 'gatsby-plugin-image';
import { getLessonsImages } from 'components/images/lessons_images';
import { FAQs } from 'components/faqs';
import { Footer } from 'components/footer';

const LessonsPage = () => {
  React.useEffect(() => {
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
  }, []);

  const { privateLesson, bowl, kid } = getLessonsImages();

  return (
    <>
      <SEO pageTitle="Lessons" />
      <div>
        <NavHeader />
        <main className="main">
          <div className="wrapper bg-white">
            <div className="content-center">
              <Container style={{ marginTop: 140, marginBottom: 200 }}>
                <Row>
                  <Col className="ms-auto me-auto d-flex justify-content-center">
                    <h1 className="title h2 text-center text-lg-start">
                      Skate Lessons
                    </h1>
                  </Col>
                </Row>
                <Row>
                  <Col md="4">
                    <Card className="card-profile card-plain">
                      <Card.Body>
                        <Card.Title as="h4" className="text-info text-start">
                          Personalized First Lesson
                        </Card.Title>
                        <p className="card-description text-start">
                          All new students must have a private first lesson in
                          order to assess skill level 🛹
                        </p>
                        <br />
                      </Card.Body>
                      <Card.Img variant="top" as="div">
                        <GatsbyImage
                          alt="Private first lesson"
                          className="img"
                          image={privateLesson}
                        />
                      </Card.Img>
                    </Card>
                  </Col>
                  <Col md="4">
                    <Card className="card-profile card-plain">
                      <Card.Body>
                        <Card.Title
                          as="h4"
                          className="text-start"
                          style={{ color: '#69008c' }}
                        >
                          Indoor Skatepark
                        </Card.Title>
                        <p className="card-description text-start">
                          Our brand new indoor skatepark at the Kahuku Sugar
                          Mill is a safe, convenient location built by
                          professionals 🔨
                        </p>
                      </Card.Body>
                      <Card.Img variant="bottom" as="div">
                        <GatsbyImage
                          alt="A private bowl for students"
                          className="img"
                          image={bowl}
                        />
                      </Card.Img>
                    </Card>
                  </Col>
                  <Col md="4">
                    <Card className="card-profile card-plain">
                      <Card.Body>
                        <Card.Title as="h4" className="text-success text-start">
                          Multiple Options
                        </Card.Title>
                        <p className="card-description text-start">
                          Students can choose to either continue private lessons
                          or be placed in a group with skaters on a similar
                          level ➕
                        </p>
                      </Card.Body>
                      <Card.Img variant="bottom" as="div">
                        <GatsbyImage
                          alt="Multiple Formats"
                          className="img"
                          image={kid}
                        />
                      </Card.Img>
                    </Card>
                  </Col>
                </Row>

                <Row style={{ marginTop: '6rem' }}>
                  <Col className="d-flex flex-column align-items-center">
                    <Card style={{ width: '100%' }}>
                      <Card.Header className="text-center bg-primary">
                        <h1 className="my-0 font-weight-bold h3 text-white text-uppercase">
                          Schedule first lesson
                        </h1>
                      </Card.Header>
                      <Card.Body>
                        <div className="d-flex my-4 justify-content-center flex-wrap">
                          <h2 className="h4 my-3 my-lg-0 mt-lg-1 text-center">
                            First, Sign the Smart Waiver Online
                          </h2>
                          <div className="d-flex flex-column text-center ms-lg-4">
                            <Button
                              as="a"
                              variant="danger"
                              href="https://waiver.smartwaiver.com/v/sunsetskates/"
                              target="_blank"
                              title="Go Sign Waiver"
                              rel="noreferrer"
                            >
                              View & Sign Waiver
                            </Button>
                          </div>
                        </div>
                        <div className="d-flex my-4 justify-content-center flex-wrap">
                          <a
                            href="https://sunsetskateshawaii.s3.us-west-1.amazonaws.com/sunset_skates_waiver_2.pdf"
                            download="Sunset Skates Waiver"
                            title="Download Waiver"
                            style={{
                              textDecoration: 'underline',
                            }}
                          >
                            <small className="mt-3">
                              Or download and print here
                            </small>
                          </a>
                        </div>

                        <Row className="mt-4 mb-2 d-flex align-items-center">
                          <Col xs={12}>
                            <iframe
                              src="https://app.acuityscheduling.com/schedule.php?owner=18777866"
                              width="100%"
                              height="1000"
                              frameBorder="0"
                            ></iframe>
                            <script
                              src="https://embed.acuityscheduling.com/js/embed.js"
                              type="text/javascript"
                            ></script>
                          </Col>
                        </Row>
                      </Card.Body>
                    </Card>
                  </Col>
                </Row>

                <Row>
                  <Col>
                    <Card className="card-plain card-blog">
                      <Card.Body>
                        <Row>
                          <Col className="ms-auto me-auto d-flex justify-content-center">
                            <h2 className="title text-center text-lg-start mb-0">
                              FAQs
                            </h2>
                          </Col>
                        </Row>
                      </Card.Body>
                      <Row className="mb-5 mb-lg-3">
                        <Col>
                          <FAQs
                            questions={[
                              {
                                question: 'Why should I start skateboarding?',
                                answer:
                                  'Skateboarding is fun! It also builds confidence and determination while fostering creativity and athletic ability.',
                              },
                              {
                                question: 'What ages do you work with?',
                                answer:
                                  'We have worked with kids as young as 1 and adults as old as 70. It’s never too early or too late to pick up a skateboard.',
                              },
                              {
                                question: 'Do I need my own equipment?',
                                answer:
                                  'We have boards, pads and helmets available for rent or purchase.',
                              },
                              {
                                question: 'How long are lessons?',
                                answer:
                                  'Lessons are typically at least one hour long, but can be scheduled longer if you want to keep shredding!',
                              },
                              {
                                question: 'Can I free skate?',
                                answer:
                                  'Open sessions are available for freeskating. ',
                              },
                              {
                                question: 'Is the park only for skateboards?',
                                answer:
                                  'Rollerskates & rollerblades are also welcome! No bikes or scooters are allowed.',
                              },
                              {
                                question: 'Have more questions?',
                                answer: (
                                  <span>
                                    Please call, text, or email us! <br />{' '}
                                    <br />
                                    Phone:{' '}
                                    <a
                                      href="tel:8087292779"
                                      className="text-info font-weight-bold"
                                    >
                                      (808) 729-2779
                                    </a>
                                    <br />
                                    Email:{' '}
                                    <a
                                      href="mailto:info@sunsetskateshawaii.com"
                                      target="_blank"
                                      rel="noopener noreferrer"
                                      className="text-info font-weight-bold"
                                    >
                                      info@sunsetskateshawaii.com
                                    </a>
                                    <br />
                                    <br />
                                    🙂
                                  </span>
                                ),
                              },
                            ]}
                          />
                        </Col>
                      </Row>
                    </Card>
                  </Col>
                </Row>
              </Container>
            </div>
          </div>
        </main>
        <Footer />
      </div>
    </>
  );
};

export default LessonsPage;
